export type NavRoute = {
  text?: string;
  url: string;
  key: string;
  children: NavRoute[];
  level?: number;
  alias?: string;
  exactMatch?: boolean;
  alternativeUrls?: string[];
  rel?: string;
  version?: "v1" | "v2";
  private?: boolean; // Show first and only in the mobile menu v2
};

export type NavRoutes = NavRoute[];

export const footerRoutes: NavRoutes = [
  {
    url: "/",
    key: "b2c.headline",
    children: [
      {
        text: "Rekommenderat",
        url: "",
        key: "b2c.recommended.headline",
        children: [
          {
            text: "iPhone 16",
            url: "/mobiltelefoner/apple/apple-iphone-16",
            key: "b2c.recommended.iphone16",
            children: [],
          },
          {
            text: "Samsung Galaxy s24",
            url: "/mobiltelefoner/samsung/samsung-galaxy-s24",
            key: "b2c.recommended.newSamsung",
            children: [],
          },
          {
            text: "Premier League",
            url: "/tv/sport/premier-league",
            key: "b2c.recommended.premierLeague",
            children: [],
          },
          {
            text: "SHL",
            url: "/tv/sport/shl",
            key: "b2c.recommended.shl",
            children: [],
          },
          {
            text: "Mobiltelefoner",
            url: "/mobiltelefoner",
            key: "b2c.recommended.phones",
            children: [],
          },
          {
            text: "Bredband",
            url: "/bredband",
            key: "b2c.recommended.broadband",
            children: [],
          },
          {
            text: "Fiber",
            url: "/bredband/fiber",
            key: "b2c.recommended.fiber",
            children: [],
          },
          {
            text: "Tv och streaming",
            url: "/tv",
            key: "b2c.recommended.streaming",
            children: [],
          },
          {
            text: "Black Friday",
            url: "/erbjudanden/black-friday",
            key: "b2c.recommended.blackFriday",
            children: [],
          },
          {
            text: "Telias erbjudanden",
            url: "/erbjudanden",
            key: "b2c.recommended.offers",
            children: [],
          },
        ],
      },
      {
        text: "Det är Telia",
        url: "",
        key: "b2c.itsTelia.headline",
        children: [
          {
            text: "Om Telia",
            url: "/privat/om/telia",
            key: "b2c.itsTelia.aboutTelia",
            children: [],
          },
          {
            text: "Press",
            url: "https://press.telia.se",
            key: "b2c.itsTelia.press",
            children: [],
          },
          {
            text: "Framtidens nät",
            url: "/privat/om/framtidensnat",
            key: "b2c.itsTelia.networkOfFuture",
            children: [],
          },
          {
            text: "Telias nät och kablar",
            url: "https://www.natet.telia.se/",
            key: "b2c.itsTelia.teliaNetwork",
            children: [],
          },
          {
            text: "Jobba hos oss",
            url: "https://www.teliacompany.com/sv/karriar/oppna-positioner",
            key: "b2c.itsTelia.career",
            children: [],
          },
          {
            text: "5G",
            url: "/privat/om/5g",
            key: "b2c.itsTelia.fiveG",
            children: [],
          },
          {
            text: "Avveckling 2G/3G",
            url: "/privat/om/5g/nu-slacker-vi-3g-och-2g-naten",
            key: "b2c.itsTelia.decommission2G3G",
            children: [],
          },
          {
            text: "Ledningsgrupp",
            url: "/privat/om/ledningen",
            key: "b2c.itsTelia.team",
            children: [],
          },
          {
            text: "Integritetspolicy",
            url: "/om/integritetspolicy",
            key: "b2c.itsTelia.privacyPolicy",
            children: [],
          },
          {
            text: "Om webbplatsen",
            url: "/privat/om/tekniska-krav",
            key: "b2c.itsTelia.aboutSite",
            children: [],
          },
        ],
      },
      {
        text: "Handla hos Telia",
        url: "",
        key: "b2c.shopping.headline",
        children: [
          {
            text: "Support",
            url: "/support",
            key: "b2c.shopping.support",
            children: [],
          },
          {
            text: "Kontakta oss",
            url: "/kontakt",
            key: "b2c.shopping.contact",
            children: [],
          },
          {
            text: "Driftinformation",
            url: "/support/felsokning-och-driftinformation",
            key: "b2c.shopping.status",
            children: [],
          },
          {
            text: "Köpinformation",
            url: "/support/kopinformation",
            key: "b2c.shopping.purchaseInfo",
            children: [],
          },
          {
            text: "Villkor",
            url: "/privat/om/villkor",
            key: "b2c.shopping.terms",
            children: [],
          },
          {
            text: "Priser",
            url: "/privat/om/priser",
            key: "b2c.shopping.prices",
            children: [],
          },
          {
            text: "Teliabutiker",
            url: "/privat/kontakt/butikerochaterforsaljare",
            key: "b2c.shopping.stores",
            children: [],
          },
          {
            text: "Speak up line",
            url: "https://secure.ethicspoint.eu/domain/media/en/gui/101615/index.html",
            key: "b2c.shopping.speakLine",
            children: [],
          },
          {
            text: "Anmälan och överträdelser",
            url: "/privat/om/anmalan-overtradelser",
            key: "b2c.shopping.violation",
            children: [],
          },
          {
            text: "Täckningskarta",
            url: "/support/tackningskarta",
            key: "b2c.shopping.coverage",
            children: [],
          },
          {
            text: "E-post till Telia",
            url: "/privat/kontakt/epost-till-telia",
            key: "b2c.shopping.email",
            children: [],
          },
        ],
      },
      {
        text: "Hållbarhet",
        url: "",
        key: "b2c.sustainability.headline",
        children: [
          {
            text: "Panta mobilen",
            url: "/mobilt/panta",
            key: "b2c.sustainability.reserve",
            children: [],
          },
          {
            text: "Begagnade mobiler",
            url: "/mobiltelefoner/begagnade",
            key: "b2c.sustainability.usedPhones",
            children: [],
          },
          {
            text: "Laga mobilen",
            url: "/support/service-och-reparation",
            key: "b2c.sustainability.repair",
            children: [],
          },
          {
            text: "Hållbarhetsarbete",
            url: "/privat/om/hallbarhet",
            key: "b2c.sustainability.sustainWork",
            children: [],
          },
          {
            text: "Miljöarbete",
            url: "/privat/om/miljo",
            key: "b2c.sustainability.envWork",
            children: [],
          },
          {
            text: "Eco-rating",
            url: "/privat/om/miljo/eco-rating-miljosmart-mobil",
            key: "b2c.sustainability.ecoRating",
            children: [],
          },
          {
            text: "Social hållbarhet",
            url: "/privat/om/social-hallbarhet",
            key: "b2c.sustainability.social",
            children: [],
          },
          {
            text: "Surfa säkert",
            url: "/artiklar/livsstil/sa-barnsakrar-du-surfplattan-och-mobilen",
            key: "b2c.sustainability.surf",
            children: [],
          },
        ],
      },
    ],
  },
  {
    text: "Företag",
    url: "/foretag",
    key: "b2b",
    children: [
      {
        text: "Rekommenderat",
        url: "",
        key: "b2b.recommended.headline",
        children: [
          {
            text: "Företagsabonnemang",
            url: "/foretag/telefoni/foretagsabonnemang",
            key: "b2b.recommended.business-subscription",
            children: [],
          },
          {
            text: "Företagstelefoner",
            url: "/foretag/telefoni/foretagstelefoner",
            key: "b2b.recommended.business-phones",
            children: [],
          },
          {
            text: "Bredband till företaget",
            url: "/foretag/uppkoppling/bredband",
            key: "b2b.recommended.business-broadband",
            children: [],
          },
          {
            text: "Cybersäkerhet",
            url: "/foretag/it/cybersakerhet",
            key: "b2b.recommended.cybersecurity",
            children: [],
          },
          {
            text: "Erbjudanden",
            url: "/foretag/erbjudanden",
            key: "b2b.recommended.offers",
            children: [],
          },
        ],
      },
      {
        text: "Om Telia",
        url: "",
        key: "b2b.about.headline",
        children: [
          {
            text: "MyBusiness",
            url: "/foretag/mybusiness/start",
            key: "b2b.about.myBusiness",
            children: [],
          },
          {
            text: "Jobba hos oss",
            url: "https://www.teliacompany.com/sv/karriar/oppna-positioner",
            key: "b2b.about.career",
            children: [],
          },
          {
            text: "Hållbarhet",
            url: "/foretag/hallbarhet",
            key: "b2b.about.sustainability",
            children: [],
          },
          {
            text: "Press och nyheter",
            url: "https://press.telia.se",
            key: "b2b.about.press",
            children: [],
          },
          {
            text: "Allmänna villkor",
            url: "/foretag/om/villkor",
            key: "b2b.about.conditions",
            children: [],
          },
          {
            text: "Integritetspolicy",
            url: "/foretag/om/integritetspolicy",
            key: "b2b.about.privacy-policy",
            children: [],
          },
        ],
      },
      {
        text: "Support",
        url: "",
        key: "b2b.support.headline",
        children: [
          {
            text: "Driftinformation",
            url: "/foretag/support/driftinformation",
            key: "b2b.support.status",
            children: [],
          },
          {
            text: "Täckningskarta",
            url: "/foretag/support/tackningskarta",
            key: "b2b.support.operating-map",
            children: [],
          },
          {
            text: "Anmälan & överträdelser",
            url: "/foretag/om/anmalan-overtradelser",
            key: "b2b.support.abuse",
            children: [],
          },
          {
            text: "Finansiering",
            url: "/foretag/losningar/produkter-tjanster/finansieringslosningar",
            key: "b2b.support.financing",
            children: [],
          },
          {
            text: "Köp- och leveransinformation",
            url: "/foretag/support/leveransinformation",
            key: "b2b.support.buy",
            children: [],
          },
          {
            text: "Överlåtelse",
            url: "/foretag/support/hjalp/overlatelse",
            key: "b2b.support.transfer",
            children: [],
          },
          {
            text: "Priser",
            url: "/foretag/om/priser",
            key: "b2b.support.pricing",
            children: [],
          },
        ],
      },
      {
        text: "Kontakta",
        url: "",
        key: "b2b.contact-us.headline",
        children: [
          {
            text: "Kontakta oss",
            url: "/foretag/support/kontakt",
            key: "b2b.contact-us.contact",
            children: [],
          },
          {
            text: "Kontakt för fastighetsägare & brf",
            url: "/foretag/fastighet",
            key: "b2b.contact-us.realestate",
            children: [],
          },
          {
            text: "Telias nät och kablar",
            url: "https://www.natet.telia.se/",
            key: "b2b.contact-us.telia-network",
            children: [],
          },
          {
            text: "Speak-Up Line",
            url: "https://secure.ethicspoint.eu/domain/media/en/gui/101615/index.html",
            key: "b2b.contact-us.speak-up-line",
            children: [],
          },
          {
            text: "Butiker och återförsäljare",
            url: "/privat/kontakt/butikerochaterforsaljare",
            key: "b2b.contact-us.stores-resellers",
            children: [],
          },
        ],
      },
    ],
  },
];

export const subFooterRoutes: NavRoutes = [
  {
    text: "Telia at Work",
    url: "/foretag/telia-at-work",
    key: "b2b.telia-at-work.",
    children: [
      {
        url: "/foretag/om/integritetspolicy",
        key: "b2b.about.privacy-policy",
        children: [],
      },
    ],
  },
];
