import { NavRoute } from "./footer";

const mittTeliaSubRoutes = [
  {
    url: "/mitt-telia/start",
    key: "b2c.mitt-telia.subscriptions",
    children: [],
  },
  {
    url: "/mitt-telia/fakturor",
    key: "b2c.mitt-telia.invoices",
    children: [],
  },
  {
    url: "/mitt-telia/meddelanden",
    key: "b2c.mitt-telia.errands",
    children: [],
  },
  {
    url: "/mitt-telia/bestallningar",
    key: "b2c.mitt-telia.orders",
    children: [],
  },
  {
    url: "/mitt-telia/telia-life",
    key: "b2c.mitt-telia.life",
    children: [],
  },
  {
    url: "/mitt-telia/mitt-konto",
    key: "b2c.mitt-telia.my-account",
    children: [],
  },
];

export const mainNavigation: NavRoute[] = [
  {
    key: "b2c.headline",
    url: "/",
    children: [
      {
        url: "/mitt-telia/start",
        key: "b2c.mitt-telia.headline",
        version: "v2",
        private: true,
        children: mittTeliaSubRoutes,
      },
      {
        url: "/mobilt",
        key: "b2c.mobile.headline",
        children: [
          {
            url: "/mobiltelefoner",
            key: "b2c.mobile.phones",
            children: [],
            exactMatch: true,
          },
          {
            url: "/mobilt/abonnemang",
            key: "b2c.mobile.subscriptions",
            children: [],
          },
          {
            url: "/kontantkort",
            key: "b2c.mobile.cash-card",
            children: [],
          },
          {
            url: "/mobilt/surfplattor",
            key: "b2c.mobile.tablets",
            children: [],
          },
          {
            url: "/mobilt/smartklockor",
            key: "b2c.mobile.smart-watches",
            children: [],
          },
          {
            url: "/mobilt/tjanster",
            key: "b2c.mobile.services",
            children: [],
          },
          {
            url: "/mobilt/tillbehor",
            key: "b2c.mobile.accessories",
            children: [],
          },
          {
            url: "/mobilt/varumarken",
            key: "b2c.mobile.brands",
            children: [],
          },
        ],
      },
      {
        url: "/bredband",
        key: "b2c.broadband.headline",
        children: [
          {
            url: "/bredband/fiber",
            key: "b2c.broadband.fiber",
            children: [],
            exactMatch: true,
          },
          {
            url: "/bredband/mobilt-bredband",
            key: "b2c.broadband.mobile-broadband",
            children: [],
            exactMatch: true,
          },
          {
            url: "/bredband/tjanster",
            key: "b2c.broadband.services",
            children: [],
          },
          {
            url: "/bredband/routrar",
            key: "b2c.broadband.routers",
            children: [],
          },
        ],
      },
      {
        url: "/tv",
        key: "b2c.tv.headline",
        children: [
          {
            url: "/tv/paket",
            key: "b2c.tv.packages",
            children: [],
          },
          {
            url: "/tv/digital-tv",
            key: "b2c.tv.digital-tv",
            children: [],
          },
          {
            url: "/tv/sport",
            key: "b2c.tv.sports",
            children: [],
          },
          {
            url: "/tv/film-och-serier",
            key: "b2c.tv.films",
            children: [],
          },
          {
            url: "/tv/tillbehor",
            key: "b2c.tv.accessories",
            children: [],
          },
        ],
      },
      {
        url: "/support",
        key: "b2c.support.headline",
        children: [
          {
            url: "/support/mobiltelefoni",
            key: "b2c.support.mobile",
            children: [],
          },
          {
            url: "/support/bredband",
            key: "b2c.support.broadband",
            children: [],
          },
          {
            url: "/support/fast-telefoni",
            key: "b2c.support.fixed-telephony",
            children: [],
          },
          {
            url: "/support/tv-och-streaming",
            key: "b2c.support.tv-streaming",
            children: [],
          },
          {
            url: "/support/appar-och-tjanster",
            key: "b2c.support.apps-services",
            children: [],
          },
        ],
      },
      {
        url: "/kontakt",
        key: "b2c.contact.headline",
        children: [],
      },
      {
        url: "/mitt-telia",
        key: "b2c.mitt-telia.headline",
        version: "v1",
        children: mittTeliaSubRoutes,
      },
    ],
  },
  {
    url: "/foretag",
    key: "b2b.headline",
    children: [
      {
        url: "https://www.telia.se/foretag/mybusiness/start",
        key: "b2b.my-business.headline",
        version: "v2",
        private: true,
        children: [
          {
            url: "/foretag/mybusiness",
            key: "b2b.my-business.back-to-mybusiness",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/mybusiness/start",
            key: "b2b.my-business.mybusiness-read-more",
            version: "v2",
            children: [],
          },
        ],
      },
      {
        url: "/foretag/telefoni",
        key: "b2b.telephony.headline",
        version: "v2",
        children: [
          {
            url: "/foretag/telefoni/foretagstelefoner",
            key: "b2b.telephony.company-phones",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/telefoni/foretagsabonnemang",
            key: "b2b.telephony.subscriptions",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/telefoni/vaxlar",
            key: "b2b.telephony.switches",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/telefoni/kontaktcenter",
            key: "b2b.telephony.call-center",
            version: "v2",
            children: [],
          },
        ],
      },
      {
        url: "/foretag/uppkoppling",
        key: "b2b.connectivity.headline",
        version: "v2",
        children: [
          {
            url: "/foretag/uppkoppling/bredband",
            key: "b2b.connectivity.broadband",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/uppkoppling/natverk",
            key: "b2b.connectivity.network",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/uppkoppling/iot",
            key: "b2b.connectivity.iot",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/uppkoppling/dataanalys",
            key: "b2b.connectivity.insights",
            version: "v2",
            children: [],
          },
        ],
      },
      {
        url: "/foretag/it",
        key: "b2b.productivity.headline",
        version: "v2",
        children: [
          {
            url: "/foretag/it/produktivitet",
            key: "b2b.productivity.productivity",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/it/it-tjanster",
            key: "b2b.productivity.it-services",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/it/cybersakerhet",
            key: "b2b.productivity.cybersecurity",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/it/hosting",
            key: "b2b.productivity.hosting",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/it/hardvara",
            key: "b2b.productivity.hardware",
            version: "v2",
            children: [],
          },
        ],
      },
      {
        url: "/foretag/mer-fran-telia",
        key: "b2b.more.headline",
        alternativeUrls: [
          "/foretag/artiklar",
          "/foretag/event",
          "/foretag/hallbarhet",
          "/foretag/erbjudanden",
        ],
        children: [
          {
            url: "/foretag/artiklar",
            key: "b2b.more.knowledge",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/event",
            key: "b2b.more.events",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/erbjudanden",
            key: "b2b.more.offers",
            version: "v2",
            children: [],
          },
          {
            url: "/foretag/hallbarhet",
            key: "b2b.more.sustainability",
            version: "v2",
            children: [],
          },
        ],
        version: "v2",
      },
      {
        url: "/foretag/support",
        key: "b2b.support.headline",
        version: "v2",
        children: [
          {
            url: "/foretag/support/driftinformation",
            key: "b2b.support.drift",
            children: [],
            version: "v2",
          },
          {
            url: "/foretag/support/hjalp/kom-igang",
            key: "b2b.support.kom-igang",
            children: [],
            version: "v2",
          },
          {
            url: "/foretag/support/kontakt",
            key: "b2b.support.contact",
            version: "v2",
            children: [],
          },
        ],
      },
      {
        url: "https://www.telia.se/foretag/losningar/produkter-tjanster",
        key: "b2b.products-services.headline",
        children: [],
        version: "v1",
      },
      {
        url: "https://www.telia.se/foretag/mybusiness/start",
        key: "b2b.my-business.headline",
        children: [],
        version: "v1",
      },
      {
        url: "https://www.telia.se/foretag/support",
        key: "b2b.support.headline",
        version: "v1",
        children: [],
      },
      {
        url: "https://www.telia.se/foretag/kontakt",
        key: "b2b.support.contact",
        children: [],
        version: "v1",
      },
    ],
  },
  {
    url: "/foretag/telia-at-work",
    key: "b2b.telia-at-work.headline",
    children: [
      {
        url: "/foretag/telia-at-work",
        key: "b2b.telia-at-work.home",
        version: "v2",
        children: [],
        exactMatch: true,
      },
      {
        url: "/foretag/telia-at-work/support",
        key: "b2b.telia-at-work.support",
        version: "v2",
        children: [],
      },
    ],
  },
];
