import { mainNavigation } from "./routes/main-navigation";
import { footerRoutes, subFooterRoutes } from "./routes/footer";
import type { NavRoute } from "./routes/footer";

// const allRoutes = [...mainNavigation, ...footerRoutes];

type RouteMap = {
  [url: string]: NavRoute;
};

type BreadcrumbMap = {
  [url: string]: NavRoute[];
};

/*
The navigation tree is a tree structure, where the url of each node is separated
from its location in the tree data structure. This allows us to move items around in the global
navigation without having to mess with urls.
*/

export const breadcrumbs = (pathname: string, includeRoot = false): NavRoute[] => {
  const mapBreadCrumbs = (urls: NavRoute[], parents: NavRoute[] | null): BreadcrumbMap =>
    urls.reduce((previous, current) => {
      const subItems = current.children
        ? mapBreadCrumbs(current.children, [...(parents || []), current])
        : undefined;

      const parentArray = (parents || []).filter((parent) => parent !== null);

      return {
        ...previous,
        [current.url]: [...parentArray, current],
        ...subItems,
      };
    }, {});

  const [root, ...crumbs] = mapBreadCrumbs(mainNavigation, null)[pathname];

  return includeRoot ? [root].concat(crumbs) : crumbs;
};

export const branchByPathname = (pathname: string): NavRoute[] => {
  const getParent = (path: string, parents: NavRoute[]): NavRoute[] => {
    const parent = parentByPathname(path);
    if (!parent) {
      return parents;
    }
    return getParent(parent.url, [parent].concat(parents));
  };
  const node = nodeByPathname(pathname);

  if (!node) {
    return [];
  }

  return getParent(node.url, [node]);
};

export const parentByPathname = (pathname: string): NavRoute => {
  return parentsByPathname()[pathname];
};

export const parentsByPathname = (): RouteMap => {
  const getLevel = (routes: NavRoute[], parent: NavRoute | null): RouteMap => {
    return routes.reduce((prev, curr) => {
      const childParents = getLevel(curr.children, curr);
      return {
        ...childParents,
        ...prev,
        [curr.url]: parent,
      };
    }, {});
  };

  return getLevel(mainNavigation, null);
};

export const nodeByPathname = (pathname: string): NavRoute => {
  return nodesByPathname()[pathname];
};

export const nodesByPathname = (): RouteMap => {
  // Adds a level field to each Route
  const getLevel = (routes: NavRoute[], level: number): RouteMap => {
    return routes.reduce((prev, curr) => {
      const childParents = getLevel(curr.children, level + 1);

      const alternativeUrls: Record<string, NavRoute> = {};
      if (curr.alternativeUrls?.length) {
        curr.alternativeUrls.forEach((currentUrlName) => {
          alternativeUrls[currentUrlName] = { level, ...curr };
        });
      }
      return {
        ...prev,
        ...childParents,
        [curr.url]: {
          level,
          ...curr,
        },
        ...alternativeUrls,
      };
    }, {});
  };

  return getLevel(mainNavigation, 0);
};

export const footerRoot = (pathname: string): NavRoute[] => {
  const rootPath = getRoot(pathname);
  const root = footerRoutes.find((route) => route.url === rootPath);
  if (root === undefined) {
    return [];
  }

  return root.children;
};

export const subFooterRoot = (pathname: string): NavRoute[] => {
  const rootPath = getRoot(pathname);
  const root = subFooterRoutes.find((route) => route.url === rootPath);
  if (root === undefined) {
    return [];
  }

  return root.children;
};

type NavRouteVersion = "v1" | "v2" | undefined;

export const mainNavigationRoot = (
  pathname: string,
  version: NavRouteVersion = undefined
): NavRoute[] => {
  const rootPath = getRoot(pathname);

  const root = mainNavigation.find((route) => route.url === rootPath);

  if (root === undefined) {
    return [];
  }

  return version ? filterByVersion(root.children, version) : root.children;
};

export const getRoot = (pathname: string): string => {
  return /^\/foretag\/telia-at-work/.test(pathname)
    ? "/foretag/telia-at-work"
    : /^\/foretag/.test(pathname)
    ? "/foretag"
    : "/";
};

export const filterByVersion = (routes: NavRoute[], version: NavRouteVersion) =>
  routes.filter((route) => {
    if (version) {
      return route.version === version || !route.version;
    }

    return !route.version;
  });
